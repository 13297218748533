<template>
  <svg viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.58203 1.1665H2.4987C2.18928 1.1665 1.89253 1.28942 1.67374 1.50821C1.45495 1.72701 1.33203 2.02375 1.33203 2.33317V11.6665C1.33203 11.9759 1.45495 12.2727 1.67374 12.4915C1.89253 12.7103 2.18928 12.8332 2.4987 12.8332H9.4987C9.80812 12.8332 10.1049 12.7103 10.3237 12.4915C10.5424 12.2727 10.6654 11.9759 10.6654 11.6665V5.24984M6.58203 1.1665L10.6654 5.24984M6.58203 1.1665V5.24984H10.6654"
      stroke="currentColor"
      stroke-width="1"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
